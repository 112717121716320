<template>
	<article
		class="camp-item"
		:class="{
			'camp-item--locked': campaign.locked
		}"
		:aria-label="'Campaign: ' + campaign.name"
	>
		<div
			class="camp-item__grid"
			:class="{
				'camp-item__grid--small': teammatePage,
				'camp-item__grid--campaign-page': campaignPage
			}"
		>
			<div
				class="camp-item__main"
				style="grid-area: main"
			>
				<figure>
					<component
						:is="campaignPage || !clickable ? 'div' : 'router-link'"
						:to="url"
						class="picto"
					>
						<CampaignAvatar :campaign="campaign" />
					</component>
				</figure>

				<div>
					<component
						:is="campaignPage ? 'h1' : clickable ? 'router-link' : 'div'"
						class="camp-item__name"
						:to="url"
						:title="campaign.name"
					>
						{{ campaign.name }}
					</component>

					<span
						v-if="isDraft"
						class="camp-item__draft"
					> DRAFT </span>
					<LeadsIndicators
						v-else
						:statistics="stat"
						:statistics-loading="statisticsLoading"
						:campaign="campaign"
						:disabled="!clickable"
					/>
				</div>
				<InfoTip
					v-if="tipOptions.visible"
					class="camp-item__tip"
					:size="17"
					:stroke="tipOptions.color"
					:tip="tipOptions.text"
				/>
			</div>

			<CampaignStatisticsDisplaying
				style="grid-area: lists"
				:campaign="campaign"
				:clickable="clickable"
				:statistics-loading="statisticsLoading"
				field="leadsListsSize"
			>
				<template #icon>
					<Icon
						icon="lists"
						w="18"
						h="18"
					/>
				</template>
				Lists of leads
			</CampaignStatisticsDisplaying>

			<CampaignStatisticsDisplaying
				v-if="!campaignPage"
				style="grid-area: total"
				:campaign="campaign"
				:clickable="clickable"
				:statistics-loading="statisticsLoading"
				field="totalLeads"
			>
				<template #icon>
					<Icon
						icon="lead"
						stroke="var(--base-theme-color)"
						w="18"
						h="18"
					/>
				</template>
				All leads
			</CampaignStatisticsDisplaying>

			<div
				class="camp-item__separator"
				style="grid-area: separ1"
			/>

			<CampaignStatisticsDisplaying
				style="grid-area: accept"
				:campaign="campaign"
				:clickable="clickable"
				:statistics-loading="statisticsLoading"
				field="acceptanceRate"
				count-field="acceptanceRateLeads"
				filter="INVITE_ACCEPTED"
			>
				Acceptance Rate
			</CampaignStatisticsDisplaying>

			<CampaignStatisticsDisplaying
				style="grid-area: reply"
				:campaign="campaign"
				:clickable="clickable"
				:statistics-loading="statisticsLoading"
				field="replyRate"
				count-field="replyRateLeads"
				filter="MESSAGE_RESPONDED"
			>
				Reply rate
				<InfoTip
					size="12"
					stroke="var(--_gray-color)"
					tip="The number of responses you received divided by the number of delivered connection requests, messages and InMails"
				/>
			</CampaignStatisticsDisplaying>

			<div
				class="camp-item__separator"
				style="grid-area: separ2"
			/>

			<div style="grid-area: date">
				<time class="camp-item__text">
					{{ $mmm_dd_yyyy(campaign.createdAt) }}
				</time>
				<span class="camp-item__subtext">Created</span>
			</div>

			<div
				class="camp-item__campaign-status"
				style="grid-area: trigger"
			>
				<CampaignStatus
					:campaign="campaign"
					:disabled="!enableToChangeStatus"
					:statistics-loading="statisticsLoading"
					@changeCampaignStatus="changeCampaignStatus"
				/>
			</div>
			<div
				v-if="!teammatePage"
				style="grid-area: context"
			>
				<InlineSpinner v-if="contextSpinner" />
				<ContextMenu
					v-else
					:top="lastInList"
				>
					<li v-if="enableToChangeStatus">
						<button @click="changeCampaignStatusFormContextMenu(campStatus !== 'ACTIVE')">
							<Icon
								:icon="campStatus === 'ACTIVE' ? 'pause' : 'play'"
								:w="15"
								:h="17"
							/>
							{{ campStatus === "ACTIVE" ? "Pause" : "Activate" }}
						</button>
					</li>
					<li>
						<button @click="openRemovingPopup">
							<Icon
								icon="trash"
								:w="15"
								:h="17"
							/>
							Remove
						</button>
					</li>
				</ContextMenu>
			</div>
		</div>
		<transition name="fade">
			<Popup
				v-if="removingPopup"
				small
				aria-label="Remove campaign (Popup)"
				@close="closeRemovingPopup"
			>
				<template #header>
					<icon
						icon="danger"
						:w="24"
						:h="24"
						fill="var(--w-color)"
					/>
					<span>Warning</span>
				</template>
				<div>
					Do you really want to remove campaign <b>{{ campaign.name }}</b>?
				</div>
				<br>
				<footer class="popup__footer">
					<AsyncButton
						:async-f="removeCampaign"
						class="btn btn--accent"
					>
						Delete campaign
					</AsyncButton>
				</footer>
			</Popup>
		</transition>
	</article>
</template>

<script>
import defaultRemoving from "@/js/mixins/defaultRemoving.mixin";
import getCampaignsIfBasicPlan from "@/js/mixins/getCampaignsIfBasicPlan.mixin";
import CampaignStatus from "@/js/components/Campaign/CampaignStatus";
import LeadsIndicators from "@/js/components/Campaign/LeadsIndicators/LeadsIndicators";
import CampaignStatisticsDisplaying from "@/js/components/Campaign/CampaignStatisticsDisplaying";
import CampaignAvatar from "@/js/components/Campaign/CampaignAvatar";

export default {
	name: "CampItem",
	components: { LeadsIndicators, CampaignStatus, CampaignAvatar, CampaignStatisticsDisplaying },
	mixins: [defaultRemoving, getCampaignsIfBasicPlan],
	props: {
		campaign: { type: Object, required: true },
		teammatePage: { type: Boolean, default: false },
		clickable: { type: Boolean, default: true },
		lastInList: { type: Boolean, default: false },
		statisticsLoading: { type: Boolean, default: false }
	},
	data: () => ({
		contextSpinner: false
	}),
	computed: {
		campaignPage() {
			return !!this.$route.params.campaignId;
		},
		stat() {
			return this.campaign.statistics;
		},
		url() {
			return !this.campaignPage && this.clickable ? "/campaigns/" + this.campaign.id : null;
		},
		enableToChangeStatus() {
			return (
				!this.campaign.locked &&
				this.clickable &&
				(!this.isDraft ||
					(this.campaign.sequenceCompleted && this.stat && this.stat.leadsListsSize)) &&
				!this.$store.getters.subscriptionPaused
			);
		},
		campStatus() {
			return this.campaign.status;
		},
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		tipStatus() {
			if (this.stat) {
				const thirtyPercent = Math.floor((this.stat.totalLeads / 100) * 30);
				const gettingReadyLeads = this.stat.gettingReadyLeads;
				const showOrangeTip =
					(gettingReadyLeads < thirtyPercent && gettingReadyLeads < 300) || gettingReadyLeads < 50;
				return !gettingReadyLeads ? "no-leads" : showOrangeTip ? "few-leads" : false;
			} else {
				return false;
			}
		},
		tipOptions() {
			return {
				visible:
					this.stat &&
					!this.stat.searchActive &&
					!this.isDraft &&
					this.campStatus !== "NOT_ACTIVE" &&
					(this.tipStatus === "no-leads" || this.tipStatus === "few-leads"),
				color:
					this.tipStatus === "no-leads" ? "var(--danger-theme-color)" : "var(--accent-theme-color)",
				text:
					this.tipStatus === "no-leads"
						? "You have no more leads for this campaign. Add new ones to keep the campaign running"
						: "Most of your leads have started the sequence. Add more leads to keep the campaign running with new leads"
			};
		}
	},
	methods: {
		async changeCampaignStatusFormContextMenu(val) {
			try {
				this.contextSpinner = true;
				await this.changeCampaignStatus(val);
			} finally {
				this.contextSpinner = false;
			}
		},
		async changeCampaignStatus(val) {
			if (this.clickable) {
				const res = await this.$store.dispatch("changeCampaignStatus", {
					id: this.campaign.id,
					status: val ? "ACTIVE" : "NOT_ACTIVE"
				});
				this.campaign.status = res.status;

				this.$toast({
					position: this.campaignPage ? "bottom" : "top",
					html: val
						? `Your campaign has been successfully activated`
						: `Your campaign is paused now`
				});
			}
		},
		async removeCampaign() {
			const notLocked = !this.campaign.locked;
			await this.$store.dispatch("deleteCampaign", this.campaign.id);
			if (notLocked) await this.getCampaignsIfBasicPlan();
			this.closeRemovingPopup();
			this.$emit("campaignRemoved");
			this.$toast(`Your campaign has been successfully removed`);
		}
	}
};
</script>

<style lang="sass">
.camp-item
	--gapSize: 10px
	--padding-left: 20px
	margin-bottom: 10px
	background-color: cl(w)
	border-radius: 10px
	padding: 10px 20px
	position: relative
	@media screen and (max-width: $tablet)
		--padding-left: 0
		padding: 10px 15px
	&--
		&locked
			.picto
				filter: grayscale(1)
			.camp-item__name
				color: cl(dark-gray)
	&__
		&grid
			display: grid
			gap: var(--gapSize)
			grid-template-columns: 330px 1fr 1fr 0.3fr 1fr 1fr 0.3fr minmax(80px, 1fr) 70px 10px
			grid-template-areas: "main lists total separ1 accept reply separ2 date trigger context"
			align-items: center
			@media screen and (max-width: $tablet)
				grid-template-columns: 1fr 1fr 1fr 1fr 70px 5px
				grid-template-areas: "main main main main main main" "accept accept reply reply date date" "lists lists total total total context"
			&--
				&small
					grid-template-columns: 330px 1fr 1fr 0.3fr 1fr 1fr 0.3fr minmax(80px, 1fr) 70px
					grid-template-areas: "main lists total separ1 accept reply separ2 date trigger"
					@media screen and (max-width: $laptop)
						grid-template-columns: 1fr 1fr 1fr 1fr 1fr
						grid-template-areas: "main main main main main" "accept accept reply reply date" "lists lists total total total"
				&campaign-page
					grid-template-columns: 330px 1fr 0.3fr 1fr 1fr 0.3fr minmax(80px, 1fr) 70px 10px
					grid-template-areas: "main lists separ1 accept reply separ2 date trigger context"
					@media screen and (max-width: $tablet)
						grid-template-columns: 1fr 1fr 1fr 1fr 70px 5px
						grid-template-areas: "main main main main main main" "accept accept accept reply reply reply " "lists lists date date date context"

		&main
			--iconSize: 60px
			width: 100%
			display: grid
			grid-template-columns: var(--iconSize) calc(100% - var(--iconSize) - var(--gapSize))
			gap: var(--gapSize)
			position: relative
			padding-right: var(--padding-left)
			@media screen and (max-width: $tablet)
				--iconSize: 48px
		&tip
			position: absolute
			top: 0
			right: var(--padding-left)
		&name
			font-size: 16px
			display: inline-block
			max-width: calc(100% - 10px)
			min-width: 20px
			overflow: hidden
			text-overflow: ellipsis
			white-space: nowrap
			color: cl(base-theme)
			margin-bottom: 5px
			&[href]
				&:hover,
				&:focus
					text-decoration: underline
			@media screen and (max-width: $desktop)
				font-size: 15px

		&centerer
			display: flex
			flex-direction: column
			justify-content: center
			align-items: flex-start
			width: 100%
		&separator
			width: 1px
			height: 100%
			background-color: cl(light-dirty)
			@media screen and (max-width: $tablet)
				display: none
		&draft
			font-size: 13px
			color: cl(_gray)
			display: block
		&subtext
			display: block
			font-size: 12px
			color: cl(_gray)
			svg
				vertical-align: sub
		&text
			font-size: 14px
			color: cl(_gray)
			@media screen and (max-width: $laptop)
				font-size: 13px
				color: cl(b)
			&--small
				font-size: 13px
		&campaign-status
			@media screen and (max-width: $tablet)
				display: none
</style>
