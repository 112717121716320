var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{staticClass:"field",class:{
			'field--inline': _vm.inline,
			'field--numeric': _vm.numeric,
			'field--limit': _vm.limit,
		},attrs:{"rules":_vm.validation,"name":_vm.inputName,"vid":_vm.vid,"mode":_vm.validationMode,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ validate, errors, classes }){return [(_vm.labelText)?_c('label',{staticClass:"field__label",attrs:{"for":_vm.inputName}},[_vm._v(" "+_vm._s(_vm.labelText)+" "),(_vm.isRequired)?_c('span',{staticClass:"field__req",attrs:{"aria-label":"Required"}},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"field__input-wrap",class:{ 'field__input-wrap--invalid': classes.invalid }},[_c('input',{ref:_vm.inputName,staticClass:"field__input",class:{
					'field__input--center': _vm.center
				},attrs:{"id":_vm.inputName,"name":_vm.inputName,"type":_vm.type,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"inputmode":_vm.inputmode || _vm.computedMode,"pattern":_vm.pattern},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('change', $event)},"input":function($event){_vm.onInput($event, validate($event))},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}}}),(_vm.ph)?_c('label',{staticClass:"field__placeholder",class:{
					'field__placeholder--hidden': _vm.value !== '',
					'field__placeholder--center': _vm.center
				},attrs:{"for":_vm.inputName}},[_vm._v(" "+_vm._s(_vm.ph)+" "),(_vm.isRequired)?_c('span',{staticClass:"field__req",attrs:{"aria-label":"Required"}},[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.validation.max && _vm.limit)?_c('div',{staticClass:"field__max"},[_vm._v(" "+_vm._s(_vm.validation.max - _vm.value.length)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.errorText || errors[0])?_c('small',{staticClass:"field__message",class:{
					'field__message--center': _vm.center
				},domProps:{"innerHTML":_vm._s(_vm.errorText || errors[0])}}):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }